import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Button from "../components/Button/Button";
import ClubCarousel from "../components/ClubCarousel/ClubCarousel";
import ClubLayout from "../components/ClubLayout/ClubLayout";
import FakeComponent from "../components/FakeComponent/FakeComponent";
import Paragraph from "../components/Paragraph/Paragraph";
import Tag from "../components/Tag/Tag";
import Title from "../components/Title/Title";
import { getCanchas } from "../firebase/canchas";
import { getClub } from "../firebase/clubs";
import { getInscripciones } from "../firebase/inscripciones";
import { getRankings } from "../firebase/rankings";
import useValidateClub from "../hooks/useValidateClub";

const ClubPage = () => {
  const { clubId } = useParams();
  const [club, setClub] = useState(null);
  const [canchas, setCanchas] = useState(null);
  const [rankings, setRankings] = useState(null);
  const [inscripciones, setInscripciones] = useState(null);
  const loading = !club || !canchas;
  const navigate = useNavigate();

  useEffect(() => {
    getClub(clubId)
      .then((data) => setClub(data))
      .catch(() => {
        console.error("Error al obtener el club");
      });
    getCanchas(clubId)
      .then((data) => setCanchas(data))
      .catch(() => {
        console.error("Error al obtener las canchas");
      });
    getRankings(clubId)
      .then((data) => setRankings(data))
      .catch(() => {
        console.error("Error al obtener los rankings");
      });
    getInscripciones(clubId)
      .then((data) => setInscripciones(data))
      .catch(() => {
        console.error("Error al obtener las inscripciones");
      });
  }, [clubId]);

  useValidateClub(club);

  return (
    <ClubLayout>
      <ClubLayout.PicturePart>
        <ClubCarousel loading={loading} club={club}></ClubCarousel>
      </ClubLayout.PicturePart>
      <ClubLayout.ContentPart>
        <ClubLayout.InfoButton onClick={() => navigate(`/club/${clubId}/info-page`)} />
        <Title loading={loading} size="xl">
          {club?.name}
        </Title>
        <Paragraph loading={loading}>{club?.description}</Paragraph>
        <ClubLayout.TagsContainer>
          {loading ? (
            <>
              <FakeComponent style={{ width: 60, height: 16 }} />
              <FakeComponent style={{ marginLeft: 8, width: 40, height: 16 }} />
            </>
          ) : (
            club?.tags?.map((tag) => (
              <Tag key={tag.label} color={tag.color}>
                {tag.label}
              </Tag>
            ))
          )}
        </ClubLayout.TagsContainer>
        <Title size="lg">Turnos disponibles</Title>
        <div style={{ display: "grid" }}>
          <Button size="lg" color="white" onClick={() => navigate(`/club/${clubId}/horarios`)}>
            Ver turnos disponibles
          </Button>
        </div>
        {rankings?.length > 0 && (
          <ClubLayout.RankingContainer>
            <Title size="lg">Rankings</Title>
            {rankings?.map((ranking) => (
              <Button
                key={ranking.id}
                size="lg"
                color="white"
                onClick={() => navigate(`/club/${clubId}/ranking/${ranking.id}`)}
              >
                {ranking.name}
              </Button>
            ))}
          </ClubLayout.RankingContainer>
        )}
        {club?.showInscripciones && (
          <ClubLayout.RankingContainer>
            <Title size="lg">Inscripciones/Torneos</Title>

            {inscripciones?.length > 0 ? (
              inscripciones?.map((inscripcion) => (
                <Button
                  key={inscripcion.id}
                  size="lg"
                  color="white"
                  onClick={() => {
                    navigate(`/club/${clubId}/inscripcion/${inscripcion.id}`);
                  }}
                >
                  {inscripcion.name}
                </Button>
              ))
            ) : (
              <Paragraph>Próximamente se habilitaran las inscripciones</Paragraph>
            )}
          </ClubLayout.RankingContainer>
        )}
      </ClubLayout.ContentPart>
    </ClubLayout>
  );
};

export default ClubPage;
