import AccountPage from "../pages/AccountPage";
import AdminPage from "../pages/AdminPage";
import AdminTurnoPage from "../pages/AdminTurnoPage";
import CanchaPage from "../pages/CanchaPage";
import ClubPage from "../pages/ClubPage";
import EmailSigninPage from "../pages/EmailSigninPage";
import FacturacionPage from "../pages/FacturacionPage";
import HomePage from "../pages/HomePage";
import HorarioPage from "../pages/HorarioPage";
import InfoPage from "../pages/InfoPage";
import InscripcionPage from "../pages/InscripcionPage";
import MenuPage from "../pages/MenuPage";
import NotificationsPage from "../pages/NotificationsPage";
import OfflinePage from "../pages/OflinePage";
import PolicyPage from "../pages/PolicyPage";
import RankingPage from "../pages/RankingPage";
import ReservasPage from "../pages/ReservasPage";
import SinginPage from "../pages/SigninPage";
import SignupPage from "../pages/SignupPage";


export const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/account",
    component: AccountPage,
  },
  {
    path: "/club/:clubId",
    component: ClubPage,
  },
  {
    path: "/club/:clubId/cancha/:canchaId",
    component: CanchaPage,
  },
  {
    path: "/club/:clubId/horarios",
    component: HorarioPage,
  },
  {
    path: "/mis-reservas",
    component: ReservasPage,
  },
  {
    path: "/admin",
    component: AdminPage,
  },
  {
    path: "/admin/turnos",
    component: AdminTurnoPage,
  },
  {
    path: "/club/:clubId/info-page",
    component: InfoPage,
  },
  {
    path: "/club/:clubId/ranking/:rankingId",
    component: RankingPage,
  },
  {
    path: "/club/:clubId/inscripcion/:inscripcionId",
    component: InscripcionPage,
  },
  {
    path: "/policy",
    component: PolicyPage,
  },
  {
    path: "/notif",
    component: NotificationsPage,
  },
  {
    path: "/offline",
    component: OfflinePage,
  },
  {
    path: "/admin/facturacion",
    component: FacturacionPage,
  },
  {
    path: "/signin",
    component: SinginPage,
    login: true,
  },
  {
    path: "/email-signin",
    component: EmailSigninPage,
    login: true,
  },
  {
    path: "/signup",
    component: SignupPage,
    login: true,
  },
  {
    path: "/menu",
    component: MenuPage,
  }
];
